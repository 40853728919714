<template>
  <div
    class="tablet-search-bar"
  >
    <artifact-search class="search" />
  </div>
  <div class="mask" />
  <div class="tag-search-wrapper">
    <cb-container>
      <div class="tag-search-area">
        <div class="selected-tag-area">
          <div class="action">
            <p
              class="text"
              :class="{ '-default': !isSelected }"
            >
              {{ isSelected ? '選択中のタグ' : '現在、タグは選択されておりません' }}
            </p>
            <button
              class="btn"
              @click="allDeleteTagAction"
            >
              <v-icon>
                mdi-trash-can
              </v-icon>
              <span class="text">

                タグをクリア
              </span>
            </button>
          </div>

          <tag-list :tag-array="searchStore.selectedTagArray" />
        </div>

        <div class="tag-selector-area">
          <template v-if="!matches">
            <div class="categories">
              <template
                v-for="category in response"
                :key="category.id"
              >
                <div
                  class="category"
                  :class="{ '-active': category.id === currentCategory.id }"
                  @click="currentCategory = category"
                >
                  {{ category.name }}
                </div>
              </template>
            </div>

            <div class="tags">
              <checkbox-tags :tags="currentCategory.cbTags" />
            </div>
          </template>

          <template
            v-else
            v-for="(category, index) in categoriesWithOpenStatus"
            :key="category.id"
          >
            <div class="drawer-category">
              <div
                class="category"
                @click="toggleOpenStatus(index)"
              >
                {{ category.name }}
                <v-icon class="icon">
                  {{ category.isOpen ? 'mdi-menu-up' : 'mdi-menu-down' }}
                </v-icon>
              </div>

              <div
                v-if="category.isOpen"
                class="tags"
              >
                <checkbox-tags :tags="category.cbTags" />
              </div>
            </div>
          </template>
        </div>

        <div class="action-area">
          <cb-btn
            class="search-btn"
            text="選択した条件で検索"
            @click="handleSearch"
          />
        </div>
      </div>
    </cb-container>
  </div>
</template>

<script setup lang="ts">
import type { IRecord } from '~/interfaces'

const emit = defineEmits(['searched'])

const route = useRoute()
const contentHeight = ref()
const { matches } = useMediaQuery(768)
const response: IRecord[] = await useCustomDollarFetch('/api/cgbank/categories-tags') as IRecord[]

const searchStore = useSearchStore()
const generateUrl = computed(() => searchStore.generateUrl)
const isSelected = computed(() => searchStore.selectedTagArray.length !== 0)

const currentCategory = ref(response[0] as IRecord)

const categoriesWithOpenStatus = ref(response.map((category: any) => ({
  ...category,
  isOpen: false,
})))

const toggleOpenStatus = (index: number) => {
  categoriesWithOpenStatus.value[index].isOpen = !categoriesWithOpenStatus.value[index].isOpen
}

const allDeleteTagAction = () => {
  searchStore.clearSelectedTags()
}

const handleSearch = async () => {
  if (route.path === '/cgbank/favorites') {
    navigateTo(`/cgbank/favorites${generateUrl.value}`)
  } else {
    navigateTo(`/cgbank/artifacts${generateUrl.value}`)
  }
  emit('searched')
}

onMounted(() => {
  nextTick()
  const headerHeight = document.querySelector('.header')?.clientHeight as number
  contentHeight.value = `calc(90dvh - ${headerHeight}px)`
  searchStore.createSelectedTags(route.query.q, route.query.des)
})
</script>

<style lang="scss" scoped>
.tablet-search-bar {
  display: none;

  > .search {
    border-radius: 4px;
    border: 1px solid #000;
  }

  @media (max-width: 1024px) {
    display: block;
    border-top: 1px solid black;
    padding: 20px;
    > .search {
      border-radius: 4px;
      border: 1px solid #000;
      background-color: white;
    }
  }
}

.mask {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(#181818, 0.7);

  @media (max-width: 768px) {
    display: none;
  }
}

.tag-search-wrapper {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: fit-content;
  padding: 2.25rem 0;
  border-radius: 0 0 1rem 1rem;
  background-color: var(--white);
  min-height: 50%;

  @media (max-width: 768px) {
    height: v-bind(contentHeight);
    border-radius: 0;
    padding: 1rem 0;
  }
}

.tag-search-area {
  gap: 0.5rem;
  height: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  > .selected-tag-area {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    line-height: normal;

    > .action {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .text {
        font-size: clamp(0.75rem, 0.6055rem + 0.6163vw, 1rem);
        font-weight: 700;
        letter-spacing: calc($body-s / 10);

        &.-default {
          color: var(--Color-Light-Text, #737584);
        }
      }

      > .btn {
        gap: 0.25rem;
        display: flex;
        align-items: center;
        font-size: 14px;
        border-radius: 2px;
        letter-spacing: 1.4px;
        color: var(--Color-Light-Text, #737584);
        background-color: var(--background);
        padding: 7.5px 14px;
        border-radius: 3.125rem;

        @media (max-width: 768px) {
          .text {
            display: none;
          }
        }
      }
    }

    > .tags {
      min-height: 2.5rem;
    }
  }

  > .tag-selector-area {
    gap: 3.5rem;
    display: flex;
    height: 324px;

    @media (max-width: 768px) {
      gap: 0.5rem;
      flex-grow: 1;
      padding-bottom: 3rem;
      flex-direction: column;
      overflow-y: scroll;
    }

    > .categories {
      width: 200px;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;

      > .category {
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        padding: 0.95rem 0;
        letter-spacing: 1.4px;
        font-style: normal;
        line-height: normal;
        border-bottom: 1px solid var(--Color-line, #d9d9de);

        &.-active {
          color: var(--primary);
        }
      }
    }

    > .tags {
      gap: 0.75rem 4.5rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0.5rem 0;
    }

    > .drawer-category {
      width: 100%;
      gap: 0.75rem;
      display: flex;
      flex-direction: column;

      > .category {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--Color-line, #d9d9de);
        line-height: normal;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.4px;

        > .icon {
          padding: 0.5rem;
          color: var(--primary);
        }
      }

      > .tags {
        gap: 0.75rem;
        display: flex;
        flex-direction: column;
      }
    }
  }

  > .action-area {
    gap: 0.25rem;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    > .search-btn {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
</style>
